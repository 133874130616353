@import "./src/variables.scss";

.tool-drawer {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $toolbox-background;
    z-index: 10; // Blueprint button group and active buttons have z-index of 4 ~ 7

    .ToolBox {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;

        .column {
            max-width: calc(1080px + 2rem);
        }
    }
}

header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .row1 {
        width: 100%;
        margin-bottom: 3rem;
        background: linear-gradient(155deg, $brand-color 0%, lighten($brand-color, 60%) 100%);
        // Purple
        //background: linear-gradient(
        //        155deg, $brand-color 0%,
        //        lighten($brand-color, 10%) 33%,
        //        lighten($brand-color, 45%) 100%
        //);
        display: flex;
        justify-content: center;

        .content {
            width: $app-width;
            height: 140px;
            max-width: $max-app-width;
            @media (min-width: map-get($grid-breakpoints, "md")) {
                height: 200px;
                padding: $small-screen-padding 0 0 0;
            }
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            .tool-brand {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;

                h1 {
                    font-size: 42px;
                    font-weight: 900;
                    color: white;
                    line-height: normal;
                    margin: 0;
                    transform: translateY(10px);
                    @media (min-width: map-get($grid-breakpoints, "md")) {
                        font-size: 84px;
                        transform: translateY(21px);
                    }
                }
            }

            .content-partner-brand {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                color: $gray1;
                transform: translateY(3px);

                .partner-logo {
                    width: 80px;
                }

                @media (min-width: map-get($grid-breakpoints, "md")) {
                    flex-direction: row;

                    .partner-logo {
                        width: 100px;
                        margin-left: 1rem;
                    }
                }
            }

            .partner-brand {
                margin: 1rem 0 1rem 2rem;
                max-width: 150px;
                height: 100%;
                flex: 1;
                @media (min-width: map-get($grid-breakpoints, "md")) {
                    max-width: 200px;
                }
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;

                .partner-logo {
                    width: 100%;
                    height: 80%;
                    object-fit: contain;
                    object-position: bottom right;
                }
            }
        }
    }
}

#status-legend {
    display: flex;
    margin-bottom: 1.5rem;

    .bp3-radio {
        margin-right: 2rem !important;
    }
}
