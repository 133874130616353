@import "./node_modules/@blueprintjs/core/lib/scss/variables.scss";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) ;
$toolbox-background:rgb(8, 105, 173);
$easing: cubic-bezier(0.225, 0.5 ,0.165 ,1);
$app-width: 95%;
$max-app-width: 1100px; // same as CapTable
$small-screen-padding: 5% / 2;
$brand-color: rgb(8, 105, 173);
//$brand-color: rgb(90, 71, 201);
$font-size-small: .8rem;
$section-icon-size: 24px;
