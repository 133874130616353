@import "./src/variables.scss";

$score-high-color: #60C3D7;
$score-med-color: #ffa000;
$score-low-color: #DF6B91;

#lt-scorecard.app-body {
    .intro {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        @media (min-width: map-get($grid-breakpoints, "md")) {
            flex-direction: row;
        }

        .description .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        p {
            font-size: 1.2rem;
            line-height: 1.62;
            @media (min-width: map-get($grid-breakpoints, "md")) {
                font-size: 1rem;
            }
        }
    }

    .column {
        width: 100%;

        @media (min-width: map-get($grid-breakpoints, "md")) {
            width: 48%;
        }
    }

    section + section {
        margin-top: 3rem;
    }

    .tools {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        //flex-direction: column;
        //align-items: center;

        > * + * {
            margin-top: 1rem;
            @media (min-width: map-get($grid-breakpoints, "md")) {
                margin-top: 0;
            }
        }

        .name-search {
            width: 100%;
            @media (min-width: map-get($grid-breakpoints, "md")) {
                width: 48%;
            }
        }

        .non-ipo-buyer-switch {
            width: 100%;
            @media (min-width: map-get($grid-breakpoints, "md")) {
                width: 48%;
            }
            height: 30px;
            display: flex;
            align-items: center;
            margin-bottom: 0;
        }

        .market-cap-range {
            width: 100%;
            @media (min-width: map-get($grid-breakpoints, "md")) {
                width: 48%;
            }
            margin-top: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            label {
                margin-bottom: 1rem;
                width: 100%;
            }
            .market-cap-range-slider {
                width: 93%;
                .bp3-slider-handle .bp3-slider-label {
                    display: none;
                }
            }
        }

        .leader-board-selector {
            width: 100%;
            @media (min-width: map-get($grid-breakpoints, "md")) {
                display: none;
            }
            .top {
                color: darken($score-high-color, 20%);
            }
            .bottom {
                color: darken($score-low-color, 20%);
            }
        }
    }

    .data {
        .leader-board {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            // Reverse of usual breakpoint detection because we only want this behavior on small screens
            @media (max-width: map-get($grid-breakpoints, "md")) {
                &.top {
                    .bottom.column {display: none}
                }
                &.bottom {
                    .top.column {display: none}
                }
            }

            @media (min-width: map-get($grid-breakpoints, "md")) {
                flex-direction: row;
            }
        }

        .column {
            min-height: 20rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media (min-width: map-get($grid-breakpoints, "md")) {
                align-items: flex-start;
            }
        }
        .offset-column {
            padding-left: 1rem;
            @media (min-width: map-get($grid-breakpoints, "md")) {
                padding-left: 3rem;
            }
        }

        h3 {
            width: 100%;
            margin-bottom: 2rem;
        }

        .top {
            h3 {
                color: darken($score-high-color, 20%);
            }
            .offset-column {
                border-left: 1px solid $score-high-color;
            }
        }
        .bottom {
            h3 {
                color: darken($score-low-color, 20%);
            }
            .offset-column {
                border-left: 1px solid $score-low-color;
            }
        }
    }

    .small-label {
        font-size: .8rem;
        color: $gray2;
    }

    .score {
        display: inline-block;
        font-size: 2rem;
        font-weight: bold;
    }
    .score-high {color: $score-high-color}
    .score-med {color: $score-med-color}
    .score-low {color: $score-low-color}
}

.score-range-visualization {
    display: flex;
    margin: 1rem 0;

    .small-label {
        margin-right: .5rem;
    }

    .score {
        //flex: 1;
        text-align: right;
    }

    .low,
    .med,
    .high {
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
    }

    .low {
        //flex: 14; // range scale by 0 ~ 100 score
        flex: 50; // range scale by percentile
        border-top: 3px solid $score-low-color;
    }
    .med {
        //flex: 40;
        flex: 35;
        border-top: 3px solid $score-med-color;
    }
    .high {
        //flex: 46;
        flex: 15;
        border-top: 3px solid $score-high-color;
    }
}

.investor-list {
    .investor-list-item + .investor-list-item {
        margin-top: 1rem;
    }
}

.investor-card {
    display: flex;
    justify-content: flex-end;
    width: 335px;
    height: 206px;
    position: relative;
    overflow: hidden;

    .left {
        position: absolute;
        left: 20px; // padding of Blueprint Card
        top: 20px; // padding of Blueprint Card
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;
    }
}

.investor-card-rank {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 15rem;
    opacity: .05;
    user-select: none;
    transform: translate(-10px, 70px);
    font-family: Georgia, "Times New Roman", Courier, serif;
    width: 10rem;
    text-align: center;
}

.investor-card-name {
    width: 15rem;
    margin-top: .5rem;

    h2 {
        margin: 0;
        font-family: Georgia, "Times New Roman", Courier, serif;
    }
}

.investor-card-score .score {
    margin-left: .3rem;
}

.investor-type {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .bp3-icon {
        margin-left: .3rem;
        svg {fill: $gray2}
    }
}

.deal-counter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .visualization {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 2.6rem;
        height: 1rem;
        margin-right: .5rem;

        span {
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: $gray3;
        }
        span + span {
            margin-left: 3px;
        }
    }
}

.geolocation {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .country {
        font-size: 1.5rem;
        margin-bottom: -4px;
    }

    .city {
        margin-left: .3rem;
    }
}
