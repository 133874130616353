@import "../variables.scss";

footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: $font-size-small;
    color: $gray1;

    p {
        margin-bottom: .2rem;
    }

    .logo-container {
        display: flex;
        order: 1;
        @media (min-width: map-get($grid-breakpoints, "md")) {
            order: unset;
        }

        .logo {
            height: 30px;
            margin-right: 1rem;
        }
    }

    .content {
        width: 100%;
        order: 2;
        margin-top: 1rem;

        @media (min-width: map-get($grid-breakpoints, "md")) {
            width: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            flex: 1;
            order: unset;
            margin-top: 0;
        }
    }
}

// In React portal, must be declared outside of footer
.terms-dialog {
    max-width: 90vw;
    max-height: 90vh;

    .bp3-dialog-body {
        overflow-y: scroll;
    }
}
