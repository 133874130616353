@import "../variables.scss";

.export-dialog {
    .bp3-form-group {
        display: flex;
        justify-content: space-between;
    }

    .bp3-dialog-footer-actions {
        align-items: center;

        .bp3-spinner {
            margin-left: 1rem;
        }
    }

    .disclaimer {
        font-size: $font-size-small;
    }
}
