//@import "~@blueprintjs/icons/lib/css/blueprint-icons.scss";
//@import "~@blueprintjs/core/lib/css/blueprint.scss";
@import "../../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "../../node_modules/@blueprintjs/core/lib/css/blueprint.css";
@import "./src/variables.scss";

#ipo-ready.app-body {
    .intro {
        width: 100%;

        @media (min-width: map-get($grid-breakpoints, "md")) {
            padding: 0;
        }

        p {
            font-size: 1.3rem;
            margin-bottom: 1.5rem;
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        @media (min-width: map-get($grid-breakpoints, "md")) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}

.section-heading {
    text-transform: capitalize;
}

.sticky {
    @media (min-width: map-get($grid-breakpoints, "md")) {
        position: sticky;
        top: 4rem;
    }
}

.task-list-item-content {
    flex: 1;
    .debug {
        display: inline;
        margin-left: 1rem;

        span {
            margin-right: .5rem;
        }
    }
}

.debug {
    background-color: #fff9c4;
}

// =================================================================================================
// General controls

//#filters {
//    margin-bottom: 1rem;
//
//    .input-row {
//        height: 2rem;
//    }
//
//    h5 {
//        margin: 0 .5rem 0 0;
//    }
//
//    .bp3-tag:hover {
//        cursor: pointer;
//        filter: brightness(0.8);
//    }
//}

//.switch-row {
//    display: flex;
//    align-items: center;
//
//    .bp3-switch {
//        margin: 0 0 0 1rem;
//    }
//}

// =================================================================================================
// Input

#input {
    flex: 1;
    @media (min-width: map-get($grid-breakpoints, "md")) {
        flex: 65;
        margin-right: 2rem;
    }

    .section + .section {
        margin-top: 3rem;
    }

    .section {
        padding: 1rem;
        @media (min-width: map-get($grid-breakpoints, "md")) {
            padding: 2rem;
        }

        & > .section-heading-container {
            margin-bottom: 2rem;

            .section-heading {
                display: flex;
                align-items: center;
                margin-bottom: .5rem;
            }

            .section-heading-icon {
                margin-right: 1rem;
            }

            .subheading {
                @media (min-width: map-get($grid-breakpoints, "md")) {
                    margin-left: calc(#{$section-icon-size} + 1rem);
                }
            }
        }

        .subheading {
            font-size: 1.3rem;
        }
    }

    .subsection .section-heading {
        display: flex;
        align-items: center;

        button {
            margin-right: 1rem;
        }
    }
}

.task-list {
    @media (min-width: map-get($grid-breakpoints, "md")) {
        margin-left: calc(#{$section-icon-size} + 1rem);
    }

    .spacer {
        width: 100%;
        height: 2rem;
    }
}

.input-row {
    display: flex;
    align-items: center;
    padding: .3rem 0;

    &:hover {
        background-color: $pt-app-background-color;
    }

    .bp3-tag {
        margin-left: .2rem;
    }

    &.wrap {
        flex-direction: column;
        align-items: flex-start;

        label {
            margin-bottom: .5rem;
        }
    }
}

.inline-help {
    margin-left: .5rem;
}

.bp3-tag + .bp3-tag {
    margin: .2rem;
}

#input.is-dirty .section:first-of-type .input-row:first-of-type .bp3-control-indicator {
    @extend .pulse;
}

// =================================================================================================
// Output

#output {
    margin-top: 3rem;
    @media (min-width: map-get($grid-breakpoints, "md")) {
        margin-top: 0;
        padding: 0;
    }

    flex: 1;
    @media (min-width: map-get($grid-breakpoints, "md")) {
        flex: 35;
    }

    .subsection + .subsection {
        margin-top: 2rem;
    }

    .subsection h5 {
        display: flex;
        align-items: center;
    }

    .stage-description {
        margin-top: .5rem;
    }

    .actions {
        margin-top: .5rem;
        display: flex;
        flex-direction: column;

        & > div {
            display: flex;
            justify-content: space-between;
        }

        & > div:first-child {
            margin-bottom: .5rem;
        }

        .bp3-button,
        .tooltip-wrapper {
            width: 48%;
        }

        .tooltip-target {
            width: 100%;
        }

        label[for=restore-progress-file-input] {
            width: 100%;
        }

        #restore-progress-file-input {
            display: none;
        }
    }
}

.progress-output {
    display: flex;
    flex-direction: column;

    & + & {
        margin-top: 1rem;
    }

    .label {
        height: 2rem;
        display: flex;
        align-items: flex-end;
    }

    .score {
        display: flex;
        align-items: center;

        span {
            width: 4rem;
            text-align: right;
        }
    }
}

.to-do-list {
    padding-left: 1rem;
}

.feedback-dialog {
    max-width: 90vw;
}

// =================================================================================================

.pulse {
    border: 1px solid rgba(16,22,26,.2);
    box-shadow: 0 0 0 rgba($brand-color, 0.62);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba($brand-color, 0.62);
    }
    70% {
        box-shadow: 0 0 0 10px rgba($brand-color, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba($brand-color, 0);
    }
}
