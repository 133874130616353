.section {
    dt, dd {
        display: inline;
    }

    dt {
        font-weight: bold;
    }

    dd + dt {
        margin-left: 3rem;
    }
}
