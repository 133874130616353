@import "./src/variables.scss";

.status {
    label {
        margin: 0 !important;

        //&:first-child {
        //    .bp3-control-indicator {
        //        border: 1px solid $red4;
        //        box-shadow: none !important;
        //    }
        //
        //    input:checked ~ .bp3-control-indicator {
        //        background-color: $red4 !important;
        //    }
        //}

        //&:nth-child(2) {
        //    .bp3-control-indicator {
        //        border: 1px solid $gold4;
        //        box-shadow: none !important;
        //    }
        //
        //    input:checked ~ .bp3-control-indicator {
        //        background-color: $gold4 !important;
        //    }
        //}
        //
        //&:last-child {
        //    .bp3-control-indicator {
        //        border: 1px solid $bp3-intent-success;
        //        box-shadow: none !important;
        //    }
        //
        //    input:checked ~ .bp3-control-indicator {
        //        background-color: $bp3-intent-success !important;
        //    }
        //}
    }
}

.bp3-radio.bp3-intent-danger {
    .bp3-control-indicator {
        box-shadow: inset 0 0 0 1px $red4 !important;
    }

    input:checked ~ .bp3-control-indicator {
        background-color: $red4 !important;
    }
}

.bp3-radio.bp3-intent-warning {
    .bp3-control-indicator {
        box-shadow: inset 0 0 0 1px $gold4 !important;
    }

    input:checked ~ .bp3-control-indicator {
        background-color: $gold4 !important;
    }
}

.bp3-radio.bp3-intent-success {
    .bp3-control-indicator {
        box-shadow: inset 0 0 0 1px $pt-intent-success !important;
    }

    input:checked ~ .bp3-control-indicator {
        background-color: $pt-intent-success !important;
    }
}
