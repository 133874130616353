@import "../variables.scss";

.tags {
    height: $section-icon-size;
    margin-right: .5rem;
    display: flex;

    div, .partner-logo {
        height: 100%;
    }

    div + div {
        margin-left: 5px;
    }
}
