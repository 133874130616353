@import "./src/variables.scss";

:root {
    font-size: 13px;

    @media (min-width: map-get($grid-breakpoints, "md")) {
        font-size: 14px;
    }
}

body {
    font-family: 'Roboto', sans-serif !important;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    background-color: $pt-app-background-color;
    margin: 0;
}

#root, .app {
    width: 100%;
    height: 100%;
}

.app {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app-body {
    width: $app-width;
    max-width: $max-app-width;
    display: flex;
    flex-direction: column;
}


// =================================================================================================

header {
    width: 100%;
}

// =================================================================================================

.same-page-link {
    color: inherit;
}

.link-button {
    color: $pt-link-color;
    cursor: pointer;
}

.unstyled-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

// =================================================================================================

footer {
    width: $app-width;
    max-width: $max-app-width;
    margin: 3rem 0 3rem 0;
    @media (min-width: map-get($grid-breakpoints, "md")) {
        padding: 0;
    }
}
