@import "./src/variables.scss";

.inline-help {
    cursor: pointer;
    svg:hover {
        fill: $pt-icon-color-hover !important;
    }
    h5 {
        line-height: 1.5rem;
    }
}
